import { Observable } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import * as moment from 'moment';
import { RedirectRoutes } from '@core/models/userRoles.enum';
import { saveAs } from 'file-saver';
import { DateFilter } from '@core/models/dateFilter.model';
import { OrderStatusEnum } from '@app/shared/enums/orderStatus.enum';
import * as FileSaver from "file-saver";
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
var UtilityService = /** @class */ (function () {
    function UtilityService(authService) {
        var _this = this;
        this.authService = authService;
        this.orderStatusEnum = OrderStatusEnum;
        this.authService.currentUser.subscribe(function (user) {
            _this.user = user;
        });
    }
    UtilityService.prototype.trackByFn = function (index, item) {
        return item.id;
    };
    UtilityService.prototype.trimFirstString = function (s) {
        if (s.includes(',')) {
            var res = s.split(',');
            return res[0];
        }
        else if (s.includes('-')) {
            var res = s.split('-');
            return res[0];
        }
        else {
            return s;
        }
    };
    UtilityService.prototype.getInitials = function (string) {
        var names = string.split(' ');
        var initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };
    UtilityService.prototype.mapInitilize = function (map) {
        // Create a new StyledMapType object, passing it an array of styles,
        // and the name to be displayed on the map type control.
        var styledMapType = new google.maps.StyledMapType([
            {
                featureType: 'administrative',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified'
                    },
                    {
                        hue: '#0066ff'
                    },
                    {
                        saturation: 74
                    },
                    {
                        lightness: 100
                    }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'poi.government',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified'
                    }
                ]
            },
            {
                featureType: 'poi.park',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'poi.business',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'road',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified'
                    }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'on'
                    },
                    {
                        weight: 0.6
                    },
                    {
                        saturation: -85
                    },
                    {
                        lightness: 61
                    }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'road.arterial',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'road.local',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'transit',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified'
                    },
                    {
                        color: '#5f94ff'
                    },
                    {
                        lightness: 26
                    },
                    {
                        gamma: 5.86
                    }
                ]
            }
        ], { name: 'Styled Map' });
        // Associate the styled map with the MapTypeId and set it to display.
        map.mapTypes.set('styled_map', styledMapType);
        map.setMapTypeId('styled_map');
    };
    UtilityService.prototype.trackingMapInitilize = function (map) {
        // Create a new StyledMapType object, passing it an array of styles,
        // and the name to be displayed on the map type control.
        var styledMapType = new google.maps.StyledMapType([
            {
                featureType: 'administrative',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified'
                    },
                    {
                        hue: '#0066ff'
                    },
                    {
                        saturation: 74
                    },
                    {
                        lightness: 100
                    }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'poi.government',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'poi.park',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'poi.business',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'road',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified'
                    }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'on'
                    },
                    {
                        weight: 0.6
                    },
                    {
                        saturation: -85
                    },
                    {
                        lightness: 61
                    }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'road.arterial',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'road.local',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'transit',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified'
                    },
                    {
                        color: '#5f94ff'
                    },
                    {
                        lightness: 26
                    },
                    {
                        gamma: 5.86
                    }
                ]
            }
        ], { name: 'Styled Map' });
        // Associate the styled map with the MapTypeId and set it to display.
        map.mapTypes.set('styled_map', styledMapType);
        map.setMapTypeId('styled_map');
    };
    UtilityService.prototype.courierMapInitilize = function (map) {
        // Create a new StyledMapType object, passing it an array of styles,
        // and the name to be displayed on the map type control.
        var styledMapType = new google.maps.StyledMapType([
            {
                featureType: 'administrative',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified'
                    },
                    {
                        hue: '#0066ff'
                    },
                    {
                        saturation: 74
                    },
                    {
                        lightness: 100
                    }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'poi.government',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified'
                    }
                ]
            },
            {
                featureType: 'poi.park',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'poi.business',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'road',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified'
                    }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'on'
                    },
                    {
                        weight: 0.6
                    },
                    {
                        saturation: -85
                    },
                    {
                        lightness: 61
                    }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'road.arterial',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'road.local',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'transit',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified'
                    },
                    {
                        color: '#5f94ff'
                    },
                    {
                        lightness: 26
                    },
                    {
                        gamma: 5.86
                    }
                ]
            }
        ], { name: 'Styled Map' });
        // Associate the styled map with the MapTypeId and set it to display.
        map.mapTypes.set('styled_map', styledMapType);
        map.setMapTypeId('styled_map');
    };
    UtilityService.prototype.fetchAddress = function (geoCoder, marker) {
        return new Observable(function (observer) {
            geoCoder.geocode({
                location: {
                    lat: marker.position.lat,
                    lng: marker.position.lng
                }
            }, function (results, status) {
                if (status === 'OK') {
                    if (results[0]) {
                        observer.next(results);
                    }
                }
                else {
                    console.log('Geocoder failed due to: ' + status);
                }
            });
        });
    };
    UtilityService.prototype.findStreet = function (r) {
        for (var i = 0; i < r.length; i++) {
            var types = r[i].types;
            for (var j = 0; j < types.length; j++) {
                var type = types[j];
                if (type === 'street_address') {
                    return this.trimFirstString(r[i].formatted_address);
                }
                if (type === 'route') {
                    return this.trimFirstString(r[i].formatted_address);
                }
                if (type === 'intersection') {
                    return this.trimFirstString(r[i].formatted_address);
                }
            }
        }
    };
    UtilityService.prototype.findBuilding = function (r) {
        for (var i = 0; i < r.length; i++) {
            var types = r[i].types;
            for (var j = 0; j < types.length; j++) {
                var type = types[j];
                if (type === 'subpremise') {
                    return this.trimFirstString(r[i].formatted_address);
                }
                if (type === 'premise') {
                    return this.trimFirstString(r[i].formatted_address);
                }
            }
        }
    };
    UtilityService.prototype.findArea = function (r) {
        for (var i = 0; i < r.length; i++) {
            var types = r[i].types;
            for (var j = 0; j < types.length; j++) {
                var type = types[j];
                if (type === 'neighborhood') {
                    return this.trimFirstString(r[i].formatted_address);
                }
                if (type === 'sublocality') {
                    return this.trimFirstString(r[i].formatted_address);
                }
                if (type === 'administrative_area_level_3') {
                    return this.trimFirstString(r[i].formatted_address);
                }
                if (type === 'administrative_area_level_1') {
                    return this.trimFirstString(r[i].formatted_address);
                }
                if (type === 'political') {
                    return this.trimFirstString(r[i].formatted_address);
                }
                if (type === 'country') {
                    return this.trimFirstString(r[i].formatted_address);
                }
            }
        }
    };
    UtilityService.prototype.setMapCenter = function (mapConfig, lat, lon) {
        return (mapConfig = {
            disableDefaultUI: false,
            mapTypeControl: false,
            scaleControl: true,
            zoomControl: true,
            controlSize: 24,
            zoom: 15.1,
            center: {
                lat: lat,
                lng: lon
            }
        });
    };
    UtilityService.prototype.removeCountryCode = function (phoneNumber, countryCode) {
        if (phoneNumber && countryCode) {
            var length_1 = countryCode.toString().length + 1;
            return phoneNumber.substring(length_1);
        }
    };
    UtilityService.prototype.addCountryCode = function (phoneNumber, countryCode) {
        if (!countryCode) {
            return;
        }
        if (phoneNumber) {
            return '+' + countryCode + phoneNumber;
        }
        else {
            return phoneNumber;
        }
    };
    UtilityService.prototype.getSecondsPassed = function (dateTime) {
        var today = new Date();
        var fromDate = new Date(dateTime);
        var diffMs = today - fromDate;
        return Math.abs(diffMs / 1000);
    };
    UtilityService.prototype.secondsToDateObj = function (seconds) {
        var time = new Date();
        time.setHours(0, 0, seconds, 0);
        return time;
    };
    UtilityService.prototype.insideZone = function (lat, lng, vs) {
        var x = lat, y = lng;
        var inside = false;
        for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            var xi = vs[i]['lat'], yi = vs[i]['lon'];
            var xj = vs[j]['lat'], yj = vs[j]['lon'];
            var intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect)
                inside = !inside;
        }
        return inside;
    };
    UtilityService.prototype.adoptObject = function (address) {
        return {
            address: {
                locality: address.locality,
                notes: address.notes,
                name: address.name,
                building: address.building,
                buildingBlock: address.buildingBlock,
                unit: address.unit,
                street: address.street,
                coordinates: {
                    lat: address.coordinates.lat,
                    lon: address.coordinates.lon
                }
            }
        };
    };
    UtilityService.prototype.buildAddressDetailInfo = function (addressList) {
        addressList.forEach(function (address) {
            if (!address.getAddressDetailInfo) {
                address.getAddressDetailInfo =
                    address.name ||
                        '' + ', ' + address.locality ||
                        '' + ', ' + address.street ||
                        '' + address.building ||
                        '' + ', ' + address.buildingBlock ||
                        '' + address.unit ||
                        '' + ', ' + address.notes ||
                        '';
            }
        });
        return addressList;
    };
    UtilityService.prototype.buildAddressDetails = function (address) {
        return address.locality + ', ' + address.notes || '';
    };
    UtilityService.prototype.getFilterWorkers = function (workersList, order) {
        var _this = this;
        var filteredDrivers = [];
        workersList.forEach(function (element) {
            if (order.dispatch.activeWorker && order.dispatch.activeWorker) {
                element.assigned = element.uid === order.dispatch.activeWorker.uid;
            }
            var pinLat = order.dispatch.pickup.address.coordinates.lat
                ? order.dispatch.pickup.address.coordinates.lat
                : order.dispatch.dropOff.address.coordinates.lat;
            var pinLon = order.dispatch.pickup.address.coordinates.lon
                ? order.dispatch.pickup.address.coordinates.lon
                : order.dispatch.dropOff.address.coordinates.lon;
            element.dist = _this.getDistance(element.lat, element.lon, pinLat, pinLon);
            if (order.idTeam && order.idTeam !== 'No Team') {
                if (element.assigned ||
                    (element.idTeam === order.idTeam &&
                        element.isVerified &&
                        element.isActive &&
                        (element.onDuty || !_this.user.hideOffDutyDrivers))) {
                    filteredDrivers.push(element);
                }
            }
            else if (!element.idTeam) {
                if (element.assigned ||
                    (element.isVerified &&
                        element.isActive &&
                        (element.onDuty || !_this.user.hideOffDutyDrivers))) {
                    filteredDrivers.push(element);
                }
            }
        });
        filteredDrivers = filteredDrivers.sort(function (a, b) {
            return a.dist - b.dist;
        });
        return filteredDrivers;
    };
    UtilityService.prototype.getMarketPlaceFilterWorkers = function (workersList, order) {
        var _this = this;
        var filteredDrivers = [];
        workersList.forEach(function (element) {
            if (order.dispatch.activeWorker && order.dispatch.activeWorker) {
                element.assigned = element.uid === order.dispatch.activeWorker.uid;
            }
            var pinLat = order.dispatch.pickup.address.coordinates.lat
                ? order.dispatch.pickup.address.coordinates.lat
                : order.dispatch.dropOff.address.coordinates.lat;
            var pinLon = order.dispatch.pickup.address.coordinates.lon
                ? order.dispatch.pickup.address.coordinates.lon
                : order.dispatch.dropOff.address.coordinates.lon;
            element.dist = _this.getDistance(element.lat, element.lon, pinLat, pinLon);
            if (order.idTeam && order.idTeam !== 'No Team') {
                if (element.assigned ||
                    (element.idTeam === order.idTeam &&
                        element.operationUid === order.store.operationUid &&
                        element.isVerified &&
                        element.isActive &&
                        (element.onDuty || !_this.user.hideOffDutyDrivers))) {
                    filteredDrivers.push(element);
                }
            }
            else if (!element.idTeam) {
                if (element.assigned ||
                    (element.isVerified &&
                        element.isActive &&
                        (element.onDuty || !_this.user.hideOffDutyDrivers))) {
                    filteredDrivers.push(element);
                }
            }
        });
        filteredDrivers = filteredDrivers.sort(function (a, b) {
            return a.dist - b.dist;
        });
        return filteredDrivers;
    };
    UtilityService.prototype.getCourierFilterWorkers = function (workersList, order) {
        var _this = this;
        var filteredDrivers = [];
        workersList.forEach(function (element) {
            if (order.workerUid) {
                element.assigned = element.uid === order.workerUid;
            }
            var pinLat = order.pickupLat ? order.pickupLat : order.deliveryLat;
            var pinLon = order.pickupLon ? order.pickupLon : order.deliveryLon;
            element.dist = _this.getDistance(element.lat, element.lon, pinLat, pinLon);
            if (order.idTeam && order.idTeam !== 'No Team') {
                if (element.idTeam === order.idTeam &&
                    element.isVerified &&
                    element.isActive) {
                    filteredDrivers.push(element);
                }
            }
            else if (!element.idTeam) {
                if (element.assigned || (element.isVerified && element.isActive)) {
                    filteredDrivers.push(element);
                }
            }
        });
        filteredDrivers = filteredDrivers.sort(function (a, b) {
            return a.dist - b.dist;
        });
        return filteredDrivers;
    };
    UtilityService.prototype.getVerifiedWorkers = function (workersList, order) {
        var _this = this;
        var filteredDrivers = [];
        workersList.forEach(function (element) {
            if (order.workerUid) {
                element.assigned = element.uid === order.workerUid;
            }
            var pinLat = order.pickupLat ? order.pickupLat : order.deliveryLat;
            var pinLon = order.pickupLon ? order.pickupLon : order.deliveryLon;
            element.dist = _this.getDistance(element.lat, element.lon, pinLat, pinLon);
            if (element.isVerified && element.isActive) {
                filteredDrivers.push(element);
            }
        });
        filteredDrivers = filteredDrivers.sort(function (a, b) {
            return a.dist - b.dist;
        });
        return filteredDrivers;
    };
    UtilityService.prototype.getDistance = function (lat1, lon1, lat2, lon2) {
        var R = 6371; // km (change this constant to get miles)
        var dLat = ((lat2 - lat1) * Math.PI) / 180;
        var dLon = ((lon2 - lon1) * Math.PI) / 180;
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos((lat1 * Math.PI) / 180) *
                Math.cos((lat2 * Math.PI) / 180) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };
    UtilityService.prototype.transform = function (key, date, agoLabel) {
        if (key === void 0) { key = ''; }
        var now = moment(new Date());
        var targetDate = moment(date);
        var elapsedTime = moment.duration(now.diff(targetDate));
        var seconds = elapsedTime['_data'].seconds;
        var minutes = elapsedTime['_data'].minutes;
        var hours = elapsedTime['_data'].hours;
        var days = elapsedTime['_data'].days;
        if (days > 0) {
            hours = hours + days * 24;
        }
        if (hours > 0) {
            if (minutes > 0) {
                if (hours > 1) {
                    if (minutes > 1) {
                        key = hours + ' hours ' + minutes + ' minutes ' + agoLabel;
                    }
                    else {
                        key = hours + ' hours ' + minutes + ' minute' + agoLabel;
                    }
                }
                else {
                    if (minutes > 1) {
                        key = hours + ' hour ' + minutes + ' minutes ' + agoLabel;
                    }
                    else {
                        key = hours + ' hour ' + minutes + ' minute ' + agoLabel;
                    }
                }
            }
            else {
                if (hours > 1) {
                    key = hours + ' hours ' + agoLabel;
                }
                else {
                    key = hours + ' hour ' + agoLabel;
                }
            }
        }
        else {
            if (minutes > 0) {
                if (seconds > 1 && minutes > 1) {
                    key = minutes + ' minutes ' + seconds + ' seconds ' + agoLabel;
                }
                else if (seconds == 1 && minutes > 1) {
                    key = minutes + ' minutes ' + seconds + ' second ' + agoLabel;
                }
                else if (seconds > 1 && minutes == 1) {
                    key = minutes + ' minute ' + seconds + ' seconds ' + agoLabel;
                }
                else if (seconds == 1 && minutes == 1) {
                    key = minutes + ' minute ' + seconds + ' second ' + agoLabel;
                }
            }
            else {
                if (seconds > 1) {
                    key = seconds + ' seconds ' + agoLabel;
                }
                else {
                    key = seconds + ' second ' + agoLabel;
                }
            }
        }
        return key;
    };
    UtilityService.prototype.transformToMin = function (key, date, createdDate) {
        if (key === void 0) { key = ''; }
        var now;
        var targetDate;
        if (createdDate) {
            now = moment(date);
            targetDate = moment(createdDate);
        }
        else {
            now = moment(new Date());
            targetDate = moment(date);
        }
        var elapsedTime = moment.duration(now.diff(targetDate));
        var minutes = elapsedTime['_data'].minutes;
        var hours = elapsedTime['_data'].hours;
        var days = elapsedTime['_data'].days;
        if (days > 0) {
            hours = hours + days * 24;
            minutes = minutes + hours * 60;
        }
        else if (hours > 0) {
            minutes = minutes + hours * 60;
        }
        if (minutes > 0) {
            key = minutes + ' min';
        }
        else {
            if (!createdDate) {
                key = 'Now';
            }
        }
        return key;
    };
    UtilityService.prototype.transformToDays = function (key, date, createdDate) {
        if (key === void 0) { key = ''; }
        var now;
        var targetDate;
        if (createdDate) {
            now = moment(date);
            targetDate = moment(createdDate);
        }
        else {
            now = moment(new Date());
            targetDate = moment(date);
        }
        var elapsedTime = moment.duration(now.diff(targetDate));
        var minutes = elapsedTime['_data'].minutes;
        var hours = elapsedTime['_data'].hours;
        var days = elapsedTime['_data'].days;
        if (days > 0) {
            key = days > 1 ? days + ' days' : days + ' day';
        }
        else if (hours > 0) {
            key = hours > 1 ? hours + ' hrs' : hours + ' hr';
        }
        else if (minutes > 0) {
            key = minutes > 1 ? minutes + ' mins' : minutes + ' min';
        }
        else {
            if (!createdDate) {
                key = '-';
            }
        }
        return key;
    };
    UtilityService.prototype.openNewTab = function (url) {
        window.open('#' + url, '_blank');
    };
    UtilityService.prototype.getISOData = function (data) {
        return moment(data.getTime() - data.getTimezoneOffset() * 60000).toISOString();
    };
    UtilityService.prototype.getRedirectUrl = function (permissions) {
        var userRolesKeys = Object.keys(permissions);
        var matchedRoutes = [];
        for (var redirectRoutesKey in RedirectRoutes) {
            if (userRolesKeys.includes(redirectRoutesKey)) {
                matchedRoutes.push(RedirectRoutes[redirectRoutesKey]);
            }
        }
        if (matchedRoutes.length > 0) {
            matchedRoutes.sort(function (a, b) {
                return a.redirectPriority - b.redirectPriority;
            });
            return matchedRoutes[0].url;
        }
    };
    UtilityService.prototype.getUpdateFieldsFromObject = function (params) {
        var keys = Object.keys(params);
        var _params = [];
        if (keys.length > 0) {
            keys.forEach(function (k) {
                _params.push({
                    path: k,
                    value: params[k]
                });
            });
        }
        return _params;
    };
    UtilityService.prototype.getLanguageCode = function (selectedLang) {
        if (selectedLang === 'Arabic') {
            return 'AR';
        }
        else if (selectedLang === 'French') {
            return 'FR';
        }
        else if (selectedLang === 'Spanish') {
            return 'ES';
        }
        if (selectedLang === 'English') {
            return 'EN';
        }
        if (selectedLang === 'German') {
            return 'DE';
        }
        if (selectedLang === 'Dutch') {
            return 'NL';
        }
        if (selectedLang === 'Portuguese') {
            return 'PT';
        }
        return 'EN';
    };
    UtilityService.prototype.getLocaleValue = function (selectedLocaleKey) {
        if (selectedLocaleKey === 'AR') {
            return 'Arabic';
        }
        else if (selectedLocaleKey === 'FR') {
            return 'French';
        }
        if (selectedLocaleKey === 'EN') {
            return 'English';
        }
        if (selectedLocaleKey === 'ES') {
            return 'Spanish';
        }
        if (selectedLocaleKey === 'DE') {
            return 'German';
        }
        if (selectedLocaleKey === 'NL') {
            return 'Dutch';
        }
        if (selectedLocaleKey === 'PT') {
            return 'Portuguese';
        }
        return 'English';
    };
    UtilityService.prototype.getVehicleTypeIcon = function (vehicleType) {
        if (vehicleType === 'CAR') {
            return 'fa fa-car';
        }
        if (vehicleType === 'MOTORCYCLE') {
            return 'fa fa-motorcycle';
        }
        if (vehicleType === 'TRUCK') {
            return 'fa fa-truck';
        }
        if (vehicleType === 'BICYCLE') {
            return 'fa fa-bicycle';
        }
        if (vehicleType === 'RUNNER') {
            return 'fa fa-user';
        }
        return;
    };
    UtilityService.prototype.floorFigure = function (figure, decimals) {
        if (figure) {
            if (!decimals)
                decimals = 2;
            var d = Math.pow(10, decimals);
            return (Number(figure * d) / d).toFixed(decimals);
        }
    };
    UtilityService.prototype.getLocalesDropDownList = function (user) {
        var langs = [];
        var locales = user.locales;
        var defaultLanguage = this.getLocaleValue(user.defaultLanguage);
        if (defaultLanguage != null && Object.keys(locales).length) {
            Object.keys(locales).forEach(function (key) {
                if (locales[key] !== defaultLanguage) {
                    langs.push(locales[key]);
                }
            });
            langs.unshift(defaultLanguage);
        }
        return langs;
    };
    UtilityService.prototype.getUserLocales = function (user) {
        var langs = [];
        var locales = user.locales;
        var defaultLanguage = this.getLocaleValue(user.defaultLanguage);
        langs[user.defaultLanguage] = defaultLanguage;
        if (defaultLanguage != null && Object.keys(locales).length) {
            Object.keys(locales).forEach(function (key) {
                if (locales[key] !== defaultLanguage) {
                    langs[key] = locales[key];
                }
            });
        }
        return langs;
    };
    UtilityService.prototype.excelDownLoadAll = function (saveFileName, data) {
        var file = new Blob([data], { type: 'application/vnd.ms-excel' });
        saveAs(file, saveFileName);
    };
    UtilityService.prototype.guid = function () {
        return (this.s4() +
            this.s4() +
            '-' +
            this.s4() +
            '-' +
            this.s4() +
            '-' +
            this.s4() +
            '-' +
            this.s4() +
            this.s4() +
            this.s4());
    };
    UtilityService.prototype.s4 = function () {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    };
    UtilityService.prototype.getZoneList = function (addDefaultZone) {
        var zoneList = [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z'
        ];
        if (addDefaultZone) {
            zoneList.unshift('Zone');
        }
        return zoneList;
    };
    UtilityService.prototype.convertDateTo12HoursFomat = function (dateToConvert) {
        var date = new Date(dateToConvert);
        var options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        var timeString = date.toLocaleString('en-US', options);
        return timeString;
    };
    UtilityService.prototype.getDateRange = function (selectedDateFilter) {
        var params = {};
        var dateFilter = new DateFilter();
        switch (selectedDateFilter) {
            case 'TODAY':
                params['dateBefore'] = dateFilter.fixDate(new Date());
                params['dateAfter'] = dateFilter.fixDate(new Date());
                break;
            case 'WEEK':
                params['dateBefore'] = dateFilter.fixDate(new Date());
                params['dateAfter'] = dateFilter.fixDate(dateFilter.firstDayOfWeek());
                break;
            case 'MONTH':
                params['dateBefore'] = dateFilter.fixDate(new Date());
                params['dateAfter'] = dateFilter.fixDate(dateFilter.firstDayOfMonth());
                break;
            case 'YEAR':
                params['dateBefore'] = dateFilter.fixDate(new Date());
                params['dateAfter'] = dateFilter.fixDate(dateFilter.firstDayOfYear());
                break;
        }
        return params;
    };
    UtilityService.prototype.isToday = function (d) {
        var today = new Date();
        var inputDate = new Date(d);
        if (today.setHours(0, 0, 0, 0) === inputDate.setHours(0, 0, 0, 0)) {
            return true;
        }
        return false;
    };
    UtilityService.prototype.drawCircle = function (point, radius, dir) {
        radius = radius * 0.621371; // conver to miles
        var d2r = Math.PI / 180; // degrees to radians
        var r2d = 180 / Math.PI; // radians to degrees
        var earthsradius = 3963; // 3963 is the radius of the earth in miles
        var points = 32;
        // find the raidus in lat/lon
        var rlat = (radius / earthsradius) * r2d;
        var rlng = rlat / Math.cos(point.lat() * d2r);
        var extp = new Array();
        if (dir == 1) {
            var start = 0;
            var end = points + 1; // one extra here makes sure we connect the path
        }
        else {
            var start = points + 1;
            var end = 0;
        }
        for (var i = start; dir == 1 ? i < end : i > end; i = i + dir) {
            var theta = Math.PI * (i / (points / 2));
            var ey = point.lng() + rlng * Math.cos(theta); // center a + radius x * cos(theta)
            var ex = point.lat() + rlat * Math.sin(theta); // center b + radius y * sin(theta)
            extp.push({ lat: ex, lng: ey });
        }
        return extp;
    };
    UtilityService.prototype.format12To24Hours = function (dateTime) {
        if (!dateTime) {
            return null;
        }
        var time = dateTime.split(' ');
        var modifier = time[1];
        var hourMinutes = time[0].split(':');
        var hours = hourMinutes[0];
        var minutes = hourMinutes[1];
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'pm') {
            hours = parseInt(hours, 10) + 12;
        }
        return hours + ':' + minutes;
        // if (!time) {
        //   return null;
        // }
        //
        // const timeFormat = time.slice(-2);
        // let minutes = time.slice(-5, -3);
        // let hours = parseInt(time.slice(0, -5));
        // if (hours == 12) {
        //   hours = 0;
        // }
        // if (timeFormat === "PM") {
        //   hours += 12;
        // }
        // return hours + ':' + minutes;
    };
    UtilityService.prototype.convert24To12Hours = function (time) {
        // Check correct time format and split into components
        time = time
            .toString()
            .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) {
            // If time format correct
            time = time.slice(1); // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    };
    UtilityService.prototype.hasInternationalItem = function (orderData) {
        var hasInternationalItem = false;
        if (orderData && orderData.orderDetails && orderData.orderDetails.items && orderData.orderStatus !== this.orderStatusEnum.NEW) {
            orderData.orderDetails.items.forEach(function (item) {
                if (item.isVirtualItem && !item.isLocalItem) {
                    hasInternationalItem = true;
                }
            });
        }
        return hasInternationalItem;
    };
    UtilityService.prototype.exportExcel = function (tableData, exportFileName) {
        var _this = this;
        import("xlsx").then(function (xlsx) {
            var worksheet = xlsx.utils.json_to_sheet(tableData);
            var workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            var excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            _this.saveAsExcelFile(excelBuffer, exportFileName);
        });
    };
    UtilityService.prototype.saveAsExcelFile = function (buffer, exportFileName) {
        var EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        var EXCEL_EXTENSION = '.xlsx';
        var data = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, exportFileName + EXCEL_EXTENSION);
    };
    UtilityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilityService_Factory() { return new UtilityService(i0.ɵɵinject(i1.AuthService)); }, token: UtilityService, providedIn: "root" });
    return UtilityService;
}());
export { UtilityService };
