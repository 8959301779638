import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as i0 from "@angular/core";
var MessagesService = /** @class */ (function () {
    function MessagesService() {
        this.internalServerErrorMessage = new BehaviorSubject('Uh Sorry, something went');
        this.serverErrorMessage = this.internalServerErrorMessage.asObservable();
    }
    MessagesService.prototype.changeServerErrorMessage = function (message) {
        this.internalServerErrorMessage.next(message);
    };
    MessagesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessagesService_Factory() { return new MessagesService(); }, token: MessagesService, providedIn: "root" });
    return MessagesService;
}());
export { MessagesService };
