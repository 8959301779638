var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { JwtDecodeService } from './jwtdecode.service';
import { LocalStorageService } from './localstorage.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./api.service";
import * as i3 from "./jwtdecode.service";
import * as i4 from "./localstorage.service";
import * as i5 from "ngx-permissions";
var AuthService = /** @class */ (function () {
    function AuthService(router, api, jwtDecode, localStorage, permissionsService) {
        this.router = router;
        this.api = api;
        this.jwtDecode = jwtDecode;
        this.localStorage = localStorage;
        this.permissionsService = permissionsService;
        this.currentUserSubject = new BehaviorSubject({});
        this.currentUser = this.currentUserSubject
            .asObservable()
            .pipe(distinctUntilChanged());
        this.isAuthenticatedSubject = new BehaviorSubject(false);
        this.isAuthenticated = this.isAuthenticatedSubject.asObservable();
        this.loginUrl = 'portal/common/user/login';
    }
    AuthService.prototype.registerUser = function (params, token) {
        params.token = token;
        return this.api.post('portal/common/user/signupUser', params).pipe(map(function (data) {
            // this.setToken(data.token);
            // this.addRoles(data.token);
            // this.setUserAuthentication(data.user, this.addStoreId(data.token));
            return data;
        }));
    };
    AuthService.prototype.attemptAuth = function (username, password) {
        var _this = this;
        var credentials = { userName: username, password: password };
        return this.api.post('portal/common/user/login', credentials).pipe(map(function (data) {
            if (data.user) {
                data.user.idTenant = data.idTenant;
            }
            _this.setToken(data.token);
            _this.addRoles(data.token);
            _this.setUserAuthentication(data.user, _this.addStoreId(data.token), data.config);
            return data;
        }));
    };
    AuthService.prototype.getBearerToken = function () {
        var token = this.localStorage.getItem('token');
        if (token) {
            return token;
        }
    };
    AuthService.prototype.populateUser = function () {
        var token = this.getBearerToken();
        if (token) {
            this.setToken(token);
            this.addRoles(token);
            this.setUserAuthentication(this.getUserObject(), this.addStoreId(token));
        }
        else {
            this.removeUserAuthentication();
            // this.router.navigateByUrl('/portal/login');
        }
    };
    AuthService.prototype.setUserAuthentication = function (user, storeId, config) {
        if (storeId === void 0) { storeId = null; }
        if (config === void 0) { config = null; }
        if (storeId)
            user.storeId = storeId;
        if (config)
            user.config = config;
        this.currentUserSubject.next(user);
        this.isAuthenticatedSubject.next(true);
        // this.isLoginAuthenticatedSubject.next(false);
        this.setUserObject(user);
    };
    AuthService.prototype.removeUserAuthentication = function () {
        this.localStorage.clearItems();
        this.currentUserSubject.next({});
        this.isAuthenticatedSubject.next(false);
        // this.isLoginAuthenticatedSubject.next(true);
    };
    AuthService.prototype.setToken = function (token) {
        this.localStorage.setItem('token', token);
    };
    AuthService.prototype.removeToken = function () {
        this.localStorage.removeItem('token');
    };
    AuthService.prototype.setUserObject = function (user) {
        this.localStorage.setItem('user', user);
    };
    AuthService.prototype.getUserObject = function () {
        return this.localStorage.getItem('user');
    };
    AuthService.prototype.addRoles = function (token) {
        var e_1, _a;
        this.permissionsService.flushPermissions();
        var rolesString = JSON.stringify(this.jwtDecode.decodeToken(token)['tags']);
        if (rolesString) {
            var rolesArray = JSON.parse(rolesString);
            try {
                for (var rolesArray_1 = __values(rolesArray), rolesArray_1_1 = rolesArray_1.next(); !rolesArray_1_1.done; rolesArray_1_1 = rolesArray_1.next()) {
                    var role = rolesArray_1_1.value;
                    this.permissionsService.addPermission(role);
                    this.permissionsService.addPermission(role);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (rolesArray_1_1 && !rolesArray_1_1.done && (_a = rolesArray_1.return)) _a.call(rolesArray_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    AuthService.prototype.addStoreId = function (token) {
        return this.jwtDecode.decodeToken(token)['storeId'];
    };
    AuthService.prototype.isLoggedIn = function () {
        return this.isAuthenticated;
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ApiService), i0.ɵɵinject(i3.JwtDecodeService), i0.ɵɵinject(i4.LocalStorageService), i0.ɵɵinject(i5.NgxPermissionsService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
