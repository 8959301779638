import { ToastyService } from 'ng2-toasty';
import * as i0 from "@angular/core";
import * as i1 from "ng2-toasty/src/toasty.service";
var NotificationService = /** @class */ (function () {
    function NotificationService(toastyService) {
        this.toastyService = toastyService;
    }
    NotificationService.prototype.errorNotification = function (message) {
        this.toastyService.error({
            title: '',
            msg: message,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        });
    };
    NotificationService.prototype.successNotification = function (message) {
        this.toastyService.success({
            title: '',
            msg: message,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        });
    };
    NotificationService.prototype.infoNotification = function (message) {
        this.toastyService.info({
            title: '',
            msg: message,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        });
    };
    NotificationService.prototype.waitNotification = function (message) {
        this.toastyService.wait({
            title: '',
            msg: message,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        });
    };
    NotificationService.prototype.warningNotification = function (message) {
        this.toastyService.warning({
            title: '',
            msg: message,
            showClose: true,
            timeout: 5000,
            theme: 'default'
        });
    };
    NotificationService.prototype.playSound = function () {
        var audio = new Audio();
        audio.src = '../../../assets/audio/success.mp3';
        audio.load();
        var playPromise = audio.play();
        if (playPromise !== null) {
            playPromise.catch(function () {
                audio.play();
            });
        }
    };
    NotificationService.prototype.playWarningSound = function () {
        var audio = new Audio();
        audio.src = '../../../assets/audio/warning.wav';
        audio.load();
        audio.play();
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.ToastyService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
