var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AppRoutesEnum } from '@core/models/appRoutes.enum';
export var UserRolesEnumExist;
(function (UserRolesEnumExist) {
    UserRolesEnumExist["CUSTOMER_BILLING"] = "CUSTOMER_BILLING";
    UserRolesEnumExist["AGENT_BILLING"] = "AGENT_BILLING";
    UserRolesEnumExist["LAST_MILE"] = "LAST_MILE";
    UserRolesEnumExist["LAST_MILE_TEAM"] = "LAST_MILE_TEAM";
    UserRolesEnumExist["MARKETPLACE"] = "MARKETPLACE";
    UserRolesEnumExist["MARKETPLACE_API"] = "MARKETPLACE_API";
    UserRolesEnumExist["USER_LIST"] = "USER_LIST";
    UserRolesEnumExist["ROLE_LIST"] = "ROLE_LIST";
    UserRolesEnumExist["CLIENT_VIEW_ORDER"] = "CLIENT_VIEW_ORDER";
    UserRolesEnumExist["CLIENT_EXPRESS_VIEW"] = "CLIENT_EXPRESS_VIEW";
    UserRolesEnumExist["CLIENT_VIEW_EXPRESS"] = "CLIENT_VIEW_EXPRESS";
    UserRolesEnumExist["CLIENT_VIEW"] = "CLIENT_VIEW";
    UserRolesEnumExist["DISPATCHER"] = "DISPATCHER";
    UserRolesEnumExist["INTERIM"] = "INTERIM";
    UserRolesEnumExist["ORDER_LIST"] = "ORDER_LIST";
    UserRolesEnumExist["REFERRALS_VIEW"] = "REFERRALS_VIEW";
    UserRolesEnumExist["MERCHANT"] = "MERCHANT";
    UserRolesEnumExist["COURIER"] = "COURIER";
    UserRolesEnumExist["COURIER_EXPRESS"] = "COURIER_EXPRESS";
    UserRolesEnumExist["WORKFORCE"] = "WORKFORCE";
    UserRolesEnumExist["API_USER"] = "API_USER";
    UserRolesEnumExist["CALL_CENTER_ORDERS"] = "CALL_CENTER_ORDERS";
    UserRolesEnumExist["CALL_CENTER_SCREEN"] = "CALL_CENTER_SCREEN";
    UserRolesEnumExist["MERCHANT_WAREHOUSE"] = "MERCHANT_WAREHOUSE";
    UserRolesEnumExist["INVENTORY_LIST"] = "INVENTORY_LIST";
    UserRolesEnumExist["INVENTORY_EDIT_ZONE"] = "INVENTORY_EDIT_ZONE";
    UserRolesEnumExist["INVENTORY_EDIT_QUANTITY"] = "INVENTORY_EDIT_QUANTITY";
    UserRolesEnumExist["INVENTORY_EDIT_PRICE"] = "INVENTORY_EDIT_PRICE";
    UserRolesEnumExist["INVENTORY_EDIT_PUBLISH"] = "INVENTORY_EDIT_PUBLISH";
    UserRolesEnumExist["INVENTORY_EDIT_THRESHOLD"] = "INVENTORY_EDIT_THRESHOLD";
    UserRolesEnumExist["TICKET_LIST"] = "TICKET_LIST";
    UserRolesEnumExist["TICKET_EDIT"] = "TICKET_EDIT";
    UserRolesEnumExist["CUSTOMER_LIST"] = "CUSTOMER_LIST";
    UserRolesEnumExist["CLIENT_VIEW_PROJECT"] = "CLIENT_VIEW_PROJECT";
    UserRolesEnumExist["CLIENT_VIEW_CUSTOMER"] = "CLIENT_VIEW_CUSTOMER";
    UserRolesEnumExist["TEAM_LIST"] = "TEAM_LIST";
    UserRolesEnumExist["ALERT_LISTING"] = "ALERT_LISTING";
    UserRolesEnumExist["TICKET_CONFIG_EDIT"] = "TICKET_CONFIG_EDIT";
    UserRolesEnumExist["RATING_LIST"] = "RATING_LIST";
    UserRolesEnumExist["TAGS_LIST"] = "TAGS_LIST";
    UserRolesEnumExist["PORTAL_USER"] = "PORTAL_USER";
    UserRolesEnumExist["RATINGS_VIEW"] = "RATINGS_VIEW";
    UserRolesEnumExist["UPDATE_TICKET_EMAIL"] = "UPDATE_TICKET_EMAIL";
    UserRolesEnumExist["ORDER_PRODUCT_QUANTITY"] = "ORDER_PRODUCT_QUANTITY";
})(UserRolesEnumExist || (UserRolesEnumExist = {}));
export var MerchantsRolesEnum;
(function (MerchantsRolesEnum) {
    MerchantsRolesEnum["MERCHANT_LIST_VIEW"] = "MERCHANT_LIST_VIEW";
    MerchantsRolesEnum["MERCHANT_VIEW"] = "MERCHANT_VIEW";
    MerchantsRolesEnum["MERCHANT_EDIT"] = "MERCHANT_EDIT";
    MerchantsRolesEnum["MERCHANT_ADD_REMOVE"] = "MERCHANT_ADD_REMOVE";
    MerchantsRolesEnum["MERCHANT_ARCHIVE"] = "MERCHANT_ARCHIVE";
    MerchantsRolesEnum["MERCHANT_CLOSE_ALL"] = "MERCHANT_CLOSE_ALL";
    MerchantsRolesEnum["MERCHANT_INFORMATION_VIEW"] = "MERCHANT_INFORMATION_VIEW";
    MerchantsRolesEnum["MERCHANT_INFORMATION_CONTACT_EDIT"] = "MERCHANT_INFORMATION_CONTACT_EDIT";
    MerchantsRolesEnum["MERCHANT_INFORMATION_CONTACT_ADD_REMOVE"] = "MERCHANT_INFORMATION_CONTACT_ADD_REMOVE";
    MerchantsRolesEnum["MERCHANT_INFORMATION_CONTACT_REGISTER_EMAIL"] = "MERCHANT_INFORMATION_CONTACT_REGISTER_EMAIL";
    MerchantsRolesEnum["MERCHANT_PRODUCT_LIST_VIEW"] = "MERCHANT_PRODUCT_LIST_VIEW";
    MerchantsRolesEnum["MERCHANT_PRODUCT_VIEW"] = "MERCHANT_PRODUCT_VIEW";
    MerchantsRolesEnum["MERCHANT_PRODUCT_EDIT"] = "MERCHANT_PRODUCT_EDIT";
    MerchantsRolesEnum["MERCHANT_PRODUCT_ADD_REMOVE"] = "MERCHANT_PRODUCT_ADD_REMOVE";
    MerchantsRolesEnum["MERCHANT_PRODUCT_DISCOUNT_ADD"] = "MERCHANT_PRODUCT_DISCOUNT_ADD";
    MerchantsRolesEnum["MERCHANT_PRODUCT_RECOMMEND"] = "MERCHANT_PRODUCT_RECOMMEND";
    MerchantsRolesEnum["MERCHANT_PRODUCT_PUBLISH"] = "MERCHANT_PRODUCT_PUBLISH";
    MerchantsRolesEnum["MERCHANT_PRODUCT_VAT"] = "MERCHANT_PRODUCT_VAT";
    MerchantsRolesEnum["MERCHANT_PRODUCT_RATING"] = "MERCHANT_PRODUCT_RATING";
    MerchantsRolesEnum["MERCHANT_PRODUCT_CUSTOMIZATIONS"] = "MERCHANT_PRODUCT_CUSTOMIZATIONS";
    MerchantsRolesEnum["MERCHANT_PRODUCT_CATEGORIES"] = "MERCHANT_PRODUCT_CATEGORIES";
    MerchantsRolesEnum["MERCHANT_PRODUCT_BULK_PRODUCTS_ADD"] = "MERCHANT_PRODUCT_BULK_PRODUCTS_ADD";
    MerchantsRolesEnum["MERCHANT_PRODUCT_BULK_ADD_TO_COLLECTIONS"] = "MERCHANT_PRODUCT_BULK_ADD_TO_COLLECTIONS";
    MerchantsRolesEnum["MERCHANT_PRODUCT_EXPORT_ALL"] = "MERCHANT_PRODUCT_EXPORT_ALL";
    MerchantsRolesEnum["MERCHANT_COLLECTIONS_VIEW"] = "MERCHANT_COLLECTIONS_VIEW";
    MerchantsRolesEnum["MERCHANT_COLLECTION_EDIT"] = "MERCHANT_COLLECTION_EDIT";
    MerchantsRolesEnum["MERCHANT_COLLECTION_ADD_REMOVE"] = "MERCHANT_COLLECTION_ADD_REMOVE";
    MerchantsRolesEnum["MERCHANT_COLLECTION_ACTIVATE"] = "MERCHANT_COLLECTION_ACTIVATE";
    MerchantsRolesEnum["MERCHANT_STORES_VIEW"] = "MERCHANT_STORES_VIEW";
    MerchantsRolesEnum["MERCHANT_STORE_EDIT"] = "MERCHANT_STORE_EDIT";
    MerchantsRolesEnum["MERCHANT_STORE_ADD_REMOVE"] = "MERCHANT_STORE_ADD_REMOVE";
    MerchantsRolesEnum["MERCHANT_STORE_ACTIVATE"] = "MERCHANT_STORE_ACTIVATE";
    MerchantsRolesEnum["MERCHANT_STORE_INFORMATION_VIEW"] = "MERCHANT_STORE_INFORMATION_VIEW";
    MerchantsRolesEnum["MERCHANT_STORE_INFORMATION_APP"] = "MERCHANT_STORE_INFORMATION_APP";
    MerchantsRolesEnum["MERCHANT_STORE_INFORMATION_STORE"] = "MERCHANT_STORE_INFORMATION_STORE";
    MerchantsRolesEnum["MERCHANT_STORE_INFORMATION_STORE_CAPLIMIT"] = "MERCHANT_STORE_INFORMATION_STORE_CAPLIMIT";
    MerchantsRolesEnum["MERCHANT_STORE_COLLECTIONS_VIEW"] = "MERCHANT_STORE_COLLECTIONS_VIEW";
    MerchantsRolesEnum["MERCHANT_STORE_CAPLIMIT_VIEW"] = "MERCHANT_STORE_CAPLIMIT_VIEW";
    MerchantsRolesEnum["MERCHANT_STORE_ADVANCED_VIEW"] = "MERCHANT_STORE_ADVANCED_VIEW";
    MerchantsRolesEnum["MERCHANT_STORE_CLOSING_DATA_VIEW"] = "MERCHANT_STORE_CLOSING_DATA_VIEW";
    MerchantsRolesEnum["MERCHANT_BRANDS_VIEW"] = "MERCHANT_BRANDS_VIEW";
    MerchantsRolesEnum["MERCHANT_BRAND_EDIT"] = "MERCHANT_BRAND_EDIT";
    MerchantsRolesEnum["MERCHANT_BRAND_ADD_REMOVE"] = "MERCHANT_BRAND_ADD_REMOVE";
    MerchantsRolesEnum["MERCHANT_ADVANCED_VIEW"] = "MERCHANT_ADVANCED_VIEW";
    MerchantsRolesEnum["MERCHANT_ADVANCED_COMMISSION"] = "MERCHANT_ADVANCED_COMMISSION";
    MerchantsRolesEnum["MERCHANT_ADVANCED_PRODUCT_VAT"] = "MERCHANT_ADVANCED_PRODUCT_VAT";
    MerchantsRolesEnum["MERCHANT_ADVANCED_POPULAR_SEARCHES"] = "MERCHANT_ADVANCED_POPULAR_SEARCHES";
    MerchantsRolesEnum["MERCHANT_ADVANCED_IMPULSE_ITEM"] = "MERCHANT_ADVANCED_IMPULSE_ITEM";
    MerchantsRolesEnum["MERCHANT_FEEDBACK_VIEW"] = "MERCHANT_FEEDBACK_VIEW";
    MerchantsRolesEnum["MERCHANT_FEEDBACK_REMOVE"] = "MERCHANT_FEEDBACK_REMOVE";
})(MerchantsRolesEnum || (MerchantsRolesEnum = {}));
export var HomePageItemRolesEnum;
(function (HomePageItemRolesEnum) {
    HomePageItemRolesEnum["HOME_PAGES_VIEW"] = "HOME_PAGES_VIEW";
    HomePageItemRolesEnum["HOME_PAGE_ADD_REMOVE"] = "HOME_PAGE_ADD_REMOVE";
    HomePageItemRolesEnum["HOME_PAGE_SECTIONS_VIEW"] = "HOME_PAGE_SECTIONS_VIEW";
    HomePageItemRolesEnum["HOME_PAGE_SECTION_ACTIVATE"] = "HOME_PAGE_SECTION_ACTIVATE";
    HomePageItemRolesEnum["HOME_PAGE_SECTION_ADD_REMOVE"] = "HOME_PAGE_SECTION_ADD_REMOVE";
    HomePageItemRolesEnum["HOME_PAGE_SECTION_EDIT"] = "HOME_PAGE_SECTION_EDIT";
})(HomePageItemRolesEnum || (HomePageItemRolesEnum = {}));
export var TagsRolesEnum;
(function (TagsRolesEnum) {
    TagsRolesEnum["TAGS_PRODUCTS_VIEW"] = "TAGS_PRODUCTS_VIEW";
    TagsRolesEnum["TAGS_STORES_VIEW"] = "TAGS_STORES_VIEW";
    TagsRolesEnum["TAGS_PRODUCT_VIEW"] = "TAGS_PRODUCT_VIEW";
    TagsRolesEnum["TAGS_PRODUCT_EDIT"] = "TAGS_PRODUCT_EDIT";
    TagsRolesEnum["TAGS_PRODUCT_ADD_REMOVE"] = "TAGS_PRODUCT_ADD_REMOVE";
    TagsRolesEnum["TAGS_PRODUCT_RELATIONS"] = "TAGS_PRODUCT_RELATIONS";
    TagsRolesEnum["TAGS_STORE_EDIT"] = "TAGS_STORE_EDIT";
    TagsRolesEnum["TAGS_STORE_ADD_REMOVE"] = "TAGS_STORE_ADD_REMOVE";
})(TagsRolesEnum || (TagsRolesEnum = {}));
export var AreaRolesEnum;
(function (AreaRolesEnum) {
    AreaRolesEnum["AREA_VIEW"] = "AREA_VIEW";
    AreaRolesEnum["AREA_ADD_REMOVE"] = "AREA_ADD_REMOVE";
    AreaRolesEnum["AREA_EDIT"] = "AREA_EDIT";
})(AreaRolesEnum || (AreaRolesEnum = {}));
export var AlertsRolesEnum;
(function (AlertsRolesEnum) {
    AlertsRolesEnum["ALERTS"] = "ALERTS";
    AlertsRolesEnum["ALERT_EDIT"] = "ALERT_EDIT";
    AlertsRolesEnum["ALERT_ACTIVATE"] = "ALERT_ACTIVATE";
    AlertsRolesEnum["ALERT_ADD_REMOVE"] = "ALERT_ADD_REMOVE";
})(AlertsRolesEnum || (AlertsRolesEnum = {}));
export var ReportsRolesEnum;
(function (ReportsRolesEnum) {
    ReportsRolesEnum["REPORT_APP_ORDERS_VIEW"] = "REPORT_APP_ORDERS_VIEW";
    ReportsRolesEnum["REPORT_REFERRAL_VIEW"] = "REPORT_REFERRAL_VIEW";
})(ReportsRolesEnum || (ReportsRolesEnum = {}));
export var ClientViewCustomerRolesEnum;
(function (ClientViewCustomerRolesEnum) {
    ClientViewCustomerRolesEnum["CUSTOMERS"] = "CUSTOMERS";
    ClientViewCustomerRolesEnum["CUSTOMER_VIEW"] = "CUSTOMER_VIEW";
    ClientViewCustomerRolesEnum["CUSTOMER_EDIT"] = "CUSTOMER_EDIT";
    ClientViewCustomerRolesEnum["CUSTOMERS_EXPORT_ALL"] = "CUSTOMERS_EXPORT_ALL";
    ClientViewCustomerRolesEnum["CUSTOMER_MANAGE_WALLET"] = "CUSTOMER_MANAGE_WALLET";
    ClientViewCustomerRolesEnum["CUSTOMER_WALLET_HISTORY"] = "CUSTOMER_WALLET_HISTORY";
    ClientViewCustomerRolesEnum["CUSTOMER_BLOCK_USER_VIEW"] = "CUSTOMER_BLOCK_USER_VIEW";
    ClientViewCustomerRolesEnum["CUSTOMER_BLOCK_USER_EDIT"] = "CUSTOMER_BLOCK_USER_EDIT";
    ClientViewCustomerRolesEnum["CUSTOMER_BLOCK_USER_ADD_REMOVE"] = "CUSTOMER_BLOCK_USER_ADD_REMOVE";
})(ClientViewCustomerRolesEnum || (ClientViewCustomerRolesEnum = {}));
export var PromotionsRolesEnum;
(function (PromotionsRolesEnum) {
    PromotionsRolesEnum["PROMOTIONS_PROMO_LIST_VIEW"] = "PROMOTIONS_PROMO_LIST_VIEW";
    PromotionsRolesEnum["PROMOTIONS_CAMPAIGN_LIST_VIEW"] = "PROMOTIONS_CAMPAIGN_LIST_VIEW";
    PromotionsRolesEnum["PROMOTIONS_BANNER_LIST_VIEW"] = "PROMOTIONS_BANNER_LIST_VIEW";
    PromotionsRolesEnum["PROMOTIONS_CASHBACK_LIST_VIEW"] = "PROMOTIONS_CASHBACK_LIST_VIEW";
    PromotionsRolesEnum["PROMOTIONS_PROMO_VIEW"] = "PROMOTIONS_PROMO_VIEW";
    PromotionsRolesEnum["PROMOTIONS_PROMO_EDIT"] = "PROMOTIONS_PROMO_EDIT";
    PromotionsRolesEnum["PROMOTIONS_PROMO_ACTIVATE"] = "PROMOTIONS_PROMO_ACTIVATE";
    PromotionsRolesEnum["PROMOTIONS_PROMO_ADD_REMOVE"] = "PROMOTIONS_PROMO_ADD_REMOVE";
    PromotionsRolesEnum["PROMOTIONS_CAMPAIGN_VIEW"] = "PROMOTIONS_CAMPAIGN_VIEW";
    PromotionsRolesEnum["PROMOTIONS_CAMPAIGN_EDIT"] = "PROMOTIONS_CAMPAIGN_EDIT";
    PromotionsRolesEnum["PROMOTIONS_CAMPAIGN_ADD_REMOVE"] = "PROMOTIONS_CAMPAIGN_ADD_REMOVE";
    PromotionsRolesEnum["PROMOTIONS_CAMPAIGN_ACTIVATE"] = "PROMOTIONS_CAMPAIGN_ACTIVATE";
    PromotionsRolesEnum["PROMOTIONS_BANNER_VIEW"] = "PROMOTIONS_BANNER_VIEW";
    PromotionsRolesEnum["PROMOTIONS_BANNER_EDIT"] = "PROMOTIONS_BANNER_EDIT";
    PromotionsRolesEnum["PROMOTIONS_BANNER_ADD_REMOVE"] = "PROMOTIONS_BANNER_ADD_REMOVE";
    PromotionsRolesEnum["PROMOTIONS_BANNER_ACTIVATE"] = "PROMOTIONS_BANNER_ACTIVATE";
    PromotionsRolesEnum["PROMOTIONS_CASHBACK_VIEW"] = "PROMOTIONS_CASHBACK_VIEW";
    PromotionsRolesEnum["PROMOTIONS_CASHBACK_EDIT"] = "PROMOTIONS_CASHBACK_EDIT";
    PromotionsRolesEnum["PROMOTIONS_CASHBACK_ADD_REMOVE"] = "PROMOTIONS_CASHBACK_ADD_REMOVE";
    PromotionsRolesEnum["PROMOTIONS_CASHBACK_ACTIVATE"] = "PROMOTIONS_CASHBACK_ACTIVATE";
})(PromotionsRolesEnum || (PromotionsRolesEnum = {}));
export var ConfigurationEnum;
(function (ConfigurationEnum) {
    ConfigurationEnum["CONFIGURATION_VIEW"] = "CONFIGURATION_VIEW";
    ConfigurationEnum["CONFIGURATION_EDIT"] = "CONFIGURATION_EDIT";
    ConfigurationEnum["CONFIGURATION_DELETE"] = "CONFIGURATION_DELETE";
    ConfigurationEnum["CONFIGURATION_DRIVER_ICON_VIEW"] = "CONFIGURATION_DRIVER_ICON_VIEW";
    ConfigurationEnum["CONFIGURATION_DRIVER_ICON_EDIT"] = "CONFIGURATION_DRIVER_ICON_EDIT";
    ConfigurationEnum["CONFIGURATION_DRIVER_ICON_DELETE"] = "CONFIGURATION_DRIVER_ICON_DELETE";
    ConfigurationEnum["CONFIGURATION_ONLINE_SURCHARGE_VIEW"] = "CONFIGURATION_ONLINE_SURCHARGE_VIEW";
    ConfigurationEnum["CONFIGURATION_ONLINE_SURCHARGE_EDIT"] = "CONFIGURATION_ONLINE_SURCHARGE_EDIT";
})(ConfigurationEnum || (ConfigurationEnum = {}));
;
export var ReportEnum;
(function (ReportEnum) {
    ReportEnum["REFERRAL_AND_PROMO_CODE_REPORT"] = "REFERRAL_AND_PROMO_CODE_REPORT";
    ReportEnum["CONSUMED_SCANNED_BAG_REPORT"] = "CONSUMED_SCANNED_BAG_REPORT";
    ReportEnum["REPORT_LIST"] = "REPORT_LIST";
})(ReportEnum || (ReportEnum = {}));
;
export var UserRolesEnum = __assign({}, UserRolesEnumExist, MerchantsRolesEnum, HomePageItemRolesEnum, TagsRolesEnum, AreaRolesEnum, AlertsRolesEnum, ReportsRolesEnum, ClientViewCustomerRolesEnum, PromotionsRolesEnum, ConfigurationEnum, ReportEnum);
export var RedirectRoutes = {
    CLIENT_VIEW_ORDER: {
        url: AppRoutesEnum.CLIENT_VIEW_DASHBOARD_URL,
        redirectPriority: 1
    },
    DISPATCHER: { url: AppRoutesEnum.DISPATCHER_TEAMS_URL, redirectPriority: 2 },
    CUSTOMER_BILLING: {
        url: AppRoutesEnum.BILLING_CUSTOMER_URL,
        redirectPriority: 3
    },
    AGENT_BILLING: { url: AppRoutesEnum.BILLING_AGENT_URL, redirectPriority: 4 },
    MERCHANT: {
        url: AppRoutesEnum.MARKETPLACE_WAREHOUSE_DASHBOARD_URL,
        redirectPriority: 5
    },
    MERCHANT_WAREHOUSE: {
        url: AppRoutesEnum.MARKETPLACE_WAREHOUSE_DASHBOARD_URL,
        redirectPriority: 6
    },
    INTERIM: { url: AppRoutesEnum.INTERIM_ORDERS_URL, redirectPriority: 7 },
    CLIENT_EXPRESS_VIEW: {
        url: AppRoutesEnum.CLIENT_EXPRESS_VIEW_BRANCHES_URL,
        redirectPriority: 8
    },
    MARKETPLACE: {
        url: AppRoutesEnum.MARKETPLACE_ORDERS_URL,
        redirectPriority: 9
    },
    COURIER: {
        url: AppRoutesEnum.COURIER_INCOMING_ORDERS_URL,
        redirectPriorty: 10
    },
    ORDER_LIST: {
        url: AppRoutesEnum.MARKETPLACE_ORDERS_URL,
        redirectPriority: 11
    },
    TICKET_LIST: {
        url: AppRoutesEnum.MARKETPLACE_INCIDENTS_URL,
        redirectPriority: 12
    },
    INVENTORY_LIST: {
        url: AppRoutesEnum.MARKETPLACE_INVENTORY_URL,
        redirectPriority: 13
    },
    REFERRALS_VIEW: {
        url: AppRoutesEnum.MARKETPLACE_REFERRALS_OVERVIEW_URL,
        redirectPriority: 14
    },
    USER_LIST: { url: AppRoutesEnum.MARKETPLACE_USERS_URL, redirectPriority: 15 },
    ROLE_LIST: { url: AppRoutesEnum.MARKETPLACE_ROLES_URL, redirectPriority: 16 },
    INCIDENT_CONFIGURATION_LIST: {
        url: AppRoutesEnum.MARKETPLACE_INCIDENT_CONFIG_URL,
        redirectPriorty: 17
    },
    PORTAL_USER: {
        url: AppRoutesEnum.MARKETPLACE_INCIDENT_CONFIG_URL,
        redirectPriorty: 18
    }
};
