var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ComponentFactoryResolver, Type, ComponentFactory } from '@angular/core';
var EntryComponentFactoryResolver = /** @class */ (function (_super) {
    __extends(EntryComponentFactoryResolver, _super);
    function EntryComponentFactoryResolver(rootResolver) {
        var _this = _super.call(this) || this;
        _this.rootResolver = rootResolver;
        _this.inCall = false;
        _this.resolvers = new Map();
        _this.resolveComponentFactory = function (component) {
            // Prevents cyclic calls.
            if (_this.inCall) {
                return null;
            }
            _this.inCall = true;
            try {
                var result = _this.resolveInternal(component);
                return result;
            }
            finally {
                _this.inCall = false;
            }
        };
        _this.resolveInternal = function (component) {
            var e_1, _a;
            try {
                for (var _b = __values(Array.from(_this.resolvers.entries())), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var _d = __read(_c.value, 2), resolver = _d[0], fn = _d[1];
                    try {
                        var factory = fn.call(resolver, component);
                        if (factory) {
                            return factory;
                        }
                    }
                    catch (_e) { }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return _this.rootResolve.call(_this.rootResolver, component);
        };
        return _this;
    }
    EntryComponentFactoryResolver.prototype.init = function () {
        this.rootResolve = this.rootResolver.resolveComponentFactory;
        this.rootResolver.resolveComponentFactory = this.resolveComponentFactory;
    };
    EntryComponentFactoryResolver.prototype.registerResolver = function (resolver) {
        var original = resolver.resolveComponentFactory;
        this.resolvers.set(resolver, original);
    };
    return EntryComponentFactoryResolver;
}(ComponentFactoryResolver));
export { EntryComponentFactoryResolver };
