import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var UIStateService = /** @class */ (function () {
    function UIStateService() {
        this.isMenuCollapsedSubject = new BehaviorSubject(true);
        this.isMenuCollapsed = this.isMenuCollapsedSubject.asObservable();
    }
    UIStateService.prototype.updateMenuCollapsed = function (isCollapsed) {
        this.isMenuCollapsedSubject.next(isCollapsed);
    };
    UIStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UIStateService_Factory() { return new UIStateService(); }, token: UIStateService, providedIn: "root" });
    return UIStateService;
}());
export { UIStateService };
