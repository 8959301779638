import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as i0 from "@angular/core";
var ReactiveFormService = /** @class */ (function () {
    function ReactiveFormService() {
    }
    ReactiveFormService.prototype.isFieldValid = function (form, field) {
        return !form.get(field).valid && form.get(field).touched;
    };
    ReactiveFormService.prototype.validateAllFormFields = function (formGroup) {
        var _this = this;
        Object.keys(formGroup.controls).forEach(function (field) {
            var control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof FormGroup) {
                _this.validateAllFormFields(control);
            }
        });
    };
    ReactiveFormService.prototype.setsValidators = function (control, required) {
        if (required) {
            control.setValidators([Validators.required]);
        }
        else {
            control.setValidators(null);
        }
        control.updateValueAndValidity();
    };
    ReactiveFormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReactiveFormService_Factory() { return new ReactiveFormService(); }, token: ReactiveFormService, providedIn: "root" });
    return ReactiveFormService;
}());
export { ReactiveFormService };
