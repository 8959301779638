var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { Injector } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MessagesService } from '../services/messages.service';
import 'rxjs/add/operator/do';
import { LoadingBarService } from '@ngx-loading-bar/core';
var TokenInterceptor = /** @class */ (function () {
    function TokenInterceptor(injector, loaderService) {
        this.injector = injector;
        this.loaderService = loaderService;
    }
    TokenInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var e_1, _a;
        this.showLoader();
        var authService = this.injector.get(AuthService);
        var router = this.injector.get(Router);
        var message = this.injector.get(MessagesService);
        var headerSettings = {};
        try {
            for (var _b = __values(req.headers.keys()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var key = _c.value;
                headerSettings[key] = req.headers.getAll(key);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var token = authService.getBearerToken();
        if (token) {
            headerSettings['Authorization'] = 'Bearer ' + token;
            headerSettings['Content-Type'] = 'application/json';
        }
        var customHeaders = new HttpHeaders(headerSettings);
        var authReq = req.clone({
            headers: customHeaders
        });
        return next.handle(authReq).do(function (event) {
            if (event instanceof HttpResponse) {
                _this.onEnd();
            }
        }, function (err) {
            if (err instanceof HttpErrorResponse) {
                var msg_1;
                if (err.status === 401) {
                    router.navigateByUrl('/portal/login');
                }
                else if (err.status === 500) {
                    message.changeServerErrorMessage(err.error.message);
                    router.navigateByUrl('/server-error/');
                }
                else if (err.status === 403) {
                    router.navigateByUrl('/access-denied/');
                }
                else if (err.status === 400) {
                    if (err.error instanceof Blob) {
                        var reader_1 = new FileReader();
                        var errorStr_1;
                        reader_1.onload = function () {
                            errorStr_1 = reader_1.result;
                            var errorJSON = JSON.parse(errorStr_1);
                            msg_1 = errorJSON.message;
                            if (errorJSON.description) {
                                msg_1 = errorJSON.description;
                            }
                        };
                        reader_1.readAsText(err.error);
                    }
                    else {
                        msg_1 = err.error.message;
                        if ('User already registered using this link' === msg_1) {
                            router.navigateByUrl('/portal/login');
                        }
                        if (err.error.description) {
                            msg_1 = err.error.description;
                        }
                    }
                }
                _this.onEnd();
            }
        });
    };
    TokenInterceptor.prototype.onEnd = function () {
        this.hideLoader();
    };
    TokenInterceptor.prototype.showLoader = function () {
        this.loaderService.start();
    };
    TokenInterceptor.prototype.hideLoader = function () {
        this.loaderService.complete();
    };
    return TokenInterceptor;
}());
export { TokenInterceptor };
export var TokenInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
};
