// Layouts
import { CommonLayoutComponent } from './common/common-layout.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';
import { AuthenticationGuard } from '@core/guards/authentication.guard';
import { AuthenticateURLGaurd } from '@core/guards/authenticateURL.gaurd';
import { PageNotFoundComponent } from '@app/common/page-not-found.component';
var ɵ0 = function () {
    return import("./courier/courier.module.ngfactory").then(function (m) { return m.CourierModuleNgFactory; });
}, ɵ1 = function () {
    return import("./workforce/workforce.module.ngfactory").then(function (m) { return m.WorkForceModuleNgFactory; });
}, ɵ2 = function () {
    return import("./outsource/outsource.module.ngfactory").then(function (m) { return m.OutSourceModuleNgFactory; });
}, ɵ3 = function () {
    return import("./client-express-view/client-express-view.module.ngfactory").then(function (m) { return m.ClientExpressViewModuleNgFactory; });
}, ɵ4 = function () {
    return import("./client-view/client-view.module.ngfactory").then(function (m) { return m.ClientViewModuleNgFactory; });
}, ɵ5 = function () {
    return import("./marketplace/marketplace.module.ngfactory").then(function (m) { return m.MarketplaceModuleNgFactory; });
}, ɵ6 = function () {
    return import("./eddress-book/eddress-book.module.ngfactory").then(function (m) { return m.EddressBookModuleNgFactory; });
}, ɵ7 = function () {
    return import("./dispatcher/dispatcher.module.ngfactory").then(function (m) { return m.DispatcherModuleNgFactory; });
}, ɵ8 = function () {
    return import("./billing/billing.module.ngfactory").then(function (m) { return m.BillingModuleNgFactory; });
}, ɵ9 = { role: 'DISPATCHER' }, ɵ10 = function () {
    return import("./admin-settings/admin-settings.module.ngfactory").then(function (m) { return m.AdminSettingsModuleNgFactory; });
}, ɵ11 = { role: 'INTERIM' }, ɵ12 = function () {
    return import("./interim-orders/interim-orders.module.ngfactory").then(function (m) { return m.InterimOrdersModuleNgFactory; });
}, ɵ13 = function () {
    return import("./last-mile/last-mile.module.ngfactory").then(function (m) { return m.LastMileModuleNgFactory; });
}, ɵ14 = function () {
    return import("./authentication/authentication.modules.ngfactory").then(function (m) { return m.AuthenticationModuleNgFactory; });
}, ɵ15 = function () {
    return import("./server-error/server-error.module.ngfactory").then(function (m) { return m.ServerErrorModuleNgFactory; });
}, ɵ16 = function () {
    return import("./access-denied/access-denied.module.ngfactory").then(function (m) { return m.AccessDeniedModuleNgFactory; });
};
export var AppRoutes = [
    {
        path: '',
        // canActivate: [AuthenticationGuard],
        redirectTo: 'portal/login',
        pathMatch: 'full'
    },
    {
        path: '',
        component: CommonLayoutComponent,
        canActivate: [AuthenticationGuard],
        canActivateChild: [NgxPermissionsGuard],
        children: [
            {
                path: 'courier',
                loadChildren: ɵ0
            },
            {
                path: 'workforce',
                loadChildren: ɵ1
            },
            {
                path: 'dashboard',
                loadChildren: ɵ2
            },
            {
                path: 'clientExpressView',
                loadChildren: ɵ3
            },
            {
                path: 'clientview',
                loadChildren: ɵ4
            },
            {
                path: 'marketplace',
                loadChildren: ɵ5
            },
            {
                path: 'eddress-book',
                loadChildren: ɵ6
            },
            {
                path: 'dispatcher',
                // canActivate: [AuthenticateURLGaurd],
                // data: { role: 'DISPATCHER' },
                loadChildren: ɵ7
            },
            {
                path: 'billing',
                loadChildren: ɵ8
            },
            {
                path: 'admin-settings',
                canActivate: [AuthenticateURLGaurd],
                data: ɵ9,
                loadChildren: ɵ10
            },
            {
                path: 'interim-orders',
                canActivate: [AuthenticateURLGaurd],
                data: ɵ11,
                loadChildren: ɵ12
            },
            {
                path: 'last-mile',
                // canActivate: [AuthenticateURLGaurd],
                // data: { role: 'INTERIM' },
                loadChildren: ɵ13
            }
        ]
    },
    {
        path: '',
        component: AuthenticationLayoutComponent,
        children: [
            {
                path: 'portal',
                loadChildren: ɵ14
            }
        ]
    },
    {
        path: '',
        component: AuthenticationLayoutComponent,
        children: [
            {
                path: 'server-error',
                loadChildren: ɵ15
            }
        ]
    },
    {
        path: '',
        component: AuthenticationLayoutComponent,
        children: [
            {
                path: 'access-denied',
                loadChildren: ɵ16
            }
        ]
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16 };
